import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'
import BoatCard from '../components/cards/boat'
import Search from '../components/search'
import Seo from '../components/seo'

class BuyPage extends Component {

  state = {
    display: this.props.data.allWpBoat || []
  }

  render() {
    let { display } = this.state
    const boats = this.props.data.allWpBoat
    return (
      <>
        <Seo title={`Buy - Lake Eildon Boat Sales`} bodyClass={`buy`} description={`Lake Eildon Boat Sales specialises in all things boat.`} />
        <section className='buy__search'>
          <div className='buy__inner'>
            <Search list={boats || []} set={list => this.setState({ display: list })} home={false} />
          </div>
        </section>
        <section className='buy__boats'>
          <div className='buy__inner'>
            <div className='buy__items'>
              { display && display.nodes.length > 0 && display.nodes.map((el, i) => (
                <BoatCard key={i} {...el} />
              ))}
            </div>
          </div>
        </section>
      </>
    )
  }
}

export const pageQuery = graphql`
  query {
    site {
      buildTime
    }
    allWpBoat(
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        title
        date
        slug
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  width: 600
                  formats: [AUTO, WEBP, AVIF]
                  placeholder: DOMINANT_COLOR
                )
              }
            }
          }
        }
        acf {
          specs {
            size
            beds
            baths
          }
          price {
            searchPrice
            displayPrice
          }
          boatStatus
          boatType
          excerpt
        }
      }
    }
  }
`

export default BuyPage
